var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('form-wizard', {
    staticClass: "mb-3",
    attrs: {
      "color": "#ae2012",
      "title": null,
      "subtitle": null,
      "shape": "square",
      "finish-button-text": "Submit",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "Pilih Rumpun",
      "before-change": _vm.validationForm
    }
  }, [_c('validation-observer', {
    ref: "pilihRumpun",
    attrs: {
      "tag": "form"
    }
  }, [_c('div', {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "50px"
    }
  }, [_c('center', [_c('validation-provider', {
    staticClass: "mt-4",
    attrs: {
      "name": "Rumpun",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('span', {
          staticClass: "mb-3"
        }, [_vm._v("Halo " + _vm._s(_vm.user.name) + ", Selamat datang di Tryout " + _vm._s(_vm.tryoutDetail.name) + " Silakan pilih rumpun terlebih dahulu sebelum memulai tryout")]), _vm._v(" "), _c('br'), _c('br'), _c('b-form-group', {
          attrs: {
            "state": errors.length > 0 ? false : null
          }
        }, [_c('v-select', {
          staticStyle: {
            "width": "50%"
          },
          attrs: {
            "id": "rumpun",
            "options": _vm.rumpunName,
            "label": "text",
            "placeholder": "Pilih Rumpun"
          },
          model: {
            value: _vm.pilihRumpun,
            callback: function callback($$v) {
              _vm.pilihRumpun = $$v;
            },
            expression: "pilihRumpun"
          }
        }), _c('b-form-invalid-feedback', {
          attrs: {
            "state": errors.length > 0 ? false : null
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")])], 1)];
      }
    }])
  })], 1)], 1)])], 1), _c('tab-content', {
    attrs: {
      "title": "Target Jurusan",
      "before-change": _vm.validationFormInfo
    }
  }, [_c('validation-observer', {
    ref: "targetJurusan",
    staticClass: "repeater-form",
    attrs: {
      "tag": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.repeateAgain($event);
      }
    }
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_c('b', [_vm._v(" Silahkan pilih univertas dan jurusan yang dituju")])]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("*maksimal 2 pilihan ")]), _vm._l(_vm.items, function (item, index) {
    return _c('b-row', {
      key: item.id,
      ref: "row",
      refInFor: true,
      staticClass: "mt-2",
      attrs: {
        "id": item.id
      }
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Universitas",
        "label-for": "universitas"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Universitas",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('v-select', {
            attrs: {
              "id": "universitas",
              "options": _vm.universitasName,
              "label": "text",
              "placeholder": "Pilih Universitas"
            },
            on: {
              "input": function input($event) {
                return _vm.fetchDataJurusan(index);
              }
            },
            model: {
              value: item.universitas,
              callback: function callback($$v) {
                _vm.$set(item, "universitas", $$v);
              },
              expression: "item.universitas"
            }
          }), _c('b-form-invalid-feedback', {
            attrs: {
              "state": errors.length > 0 ? false : null
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Jurusan",
        "label-for": "jurusan"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": "Jurusan",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('v-select', {
            attrs: {
              "id": "jurusan",
              "options": item.jurusanName,
              "label": "text",
              "placeholder": "Pilih Jurusan"
            },
            model: {
              value: item.jurusan,
              callback: function callback($$v) {
                _vm.$set(item, "jurusan", $$v);
              },
              expression: "item.jurusan"
            }
          }), _c('b-form-invalid-feedback', {
            attrs: {
              "state": errors.length > 0 ? false : null
            }
          }, [_vm._v(" " + _vm._s(errors[0]) + " ")])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  })], 2), _c('center', [this.items.length <= 1 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.repeateAgain
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', [_vm._v("Tambah")])], 1) : _vm._e(), this.items.length > 1 ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 ml-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "TrashIcon"
    }
  }), _c('span', [_vm._v("Hapus")])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }